@import "~@lysaab/ui-2/components/styling/_variables";

.onfido-intro-page {
  .as-link {
    color: $color-primary-enabled;
    transition: color 300ms ease;
    text-decoration: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: inherit;
    display: inline;

    &:visited {
      color: $color-primary-enabled;
    }

    &:active,
    &:hover,
    &:focus {
      color: $color-primary-hover;
    }
  }
    .lysa-ui-button.intro-hidden-submit {
      position: absolute;
      visibility: hidden;
    }
}
