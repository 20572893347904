.onfido-process-page {
  @keyframes ellipsis {
    to {
      width: 0.8em;
    }
  }

  .card {
    min-height: 350px;
  }

  .overlay-wrapper-position {
    position: relative;
    z-index: 100;

    .lysa-ui-snackbar {
      position: absolute;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 200;
    }
  }

  .onfido-sdk-ui-PageTitle-titleSpan {
    font-size: 28px;
  }

  .loading-wrapper {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);

    .loading-info {
      display: flex;
      justify-content: center;
    }

    .loading-text {
      position: relative;
      margin-left: -0.5em;

      .ellipsis {
        position: absolute;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 2000ms infinite;
        width: 0px;
      }
    }
  }

  .onfido-mount {
    transition: opacity 600ms;

    &.hidden-mount {
      opacity: 0;
    }

    &.small-mount {
      opacity: 0;
      min-height: 300px;
    }

    .onfido-sdk-ui-Theme-helpList {
      li {
        font-size: 14px;
      }
    }

    .onfido-sdk-ui-Modal-inner {
      min-height: 720px; // This makes it fit the "continue on your phone" screen
      width: initial;
      max-width: initial;
      border: none;
    }

    .onfido-sdk-ui-Button-button + .onfido-sdk-ui-Button-button {
      margin-left: 2em;
    }

    @media (max-width: 479px) {
      .onfido-sdk-ui-Modal-inner {
        height: initial;
        position: relative;
        border-radius: initial;
        min-height: 25em;
      }

      .onfido-sdk-ui-PageTitle-titleSpan {
        font-size: 1.5em;
      }

      .onfido-sdk-ui-Theme-step {
        height: initial;
        min-height: 25em;
      }

      .onfido-sdk-ui-Confirm-btn-secondary {
        width: 6em;
      }

      .onfido-sdk-ui-EnlargedPreview-container
        .onfido-sdk-ui-EnlargedPreview-button {
        white-space: nowrap;
      }

      .onfido-sdk-ui-Theme-fullScreenContentWrapper {
        min-height: 30em;
      }

      .onfido-sdk-ui-DocumentSelector-list {
        margin: 0;
      }

      .onfido-sdk-ui-Confirm-CaptureViewer-pdfWrapper,
      .onfido-sdk-ui-Confirm-CaptureViewer-imageWrapper,
      .onfido-sdk-ui-Confirm-CaptureViewer-videoWrapper,
      .onfido-sdk-ui-Confirm-CaptureViewer-croppedImageWrapper {
        margin: 1em 0;
      }

      .onfido-sdk-ui-PageTitle-titleWrapper {
        margin: 0 0 1em;
      }

      .onfido-sdk-ui-NavigationBar-navigation {
        padding: 0;
      }

      .onfido-sdk-ui-Confirm-message {
        margin: 0 0 1em;
      }

      .onfido-sdk-ui-Confirm-actionsContainer {
        margin: 0 0 1em;
      }

      .onfido-sdk-ui-Error-container,
      .onfido-sdk-ui-Error-container-error,
      .onfido-sdk-ui-Error-container-warning {
        margin: 0 0 0.5em;
      }

      .onfido-sdk-ui-Complete-wrapper {
        margin-top: 4.25em;
      }
    }
  }
}
