.onfido-status-page {
  @keyframes ellipsis {
    to {
      width: 0.8em;
    }
  }

  .loading-wrapper {
    .loading-info {
      display: flex;
      justify-content: center;
    }

    .loading-text {
      position: relative;
      margin-left: -0.5em;

      .ellipsis {
        position: absolute;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 2000ms infinite;
        width: 0px;
      }
    }
  }

  .snackbar-wrapper {
    text-align: left;
    list-style: none;
    margin-left: 0;

    .lysa-ui-snackbar {
      justify-content: flex-start;
      text-align: left;
    }

    .list-breakdowns {
      margin-left: 0;
      list-style: none;

      li {
        list-style: none;
        &:first-child {
          margin-top: 16px;
        }

        font-weight: bold;
        margin-top: 4px;
      }
    }
  }

  .input-error {
    margin-top: -32px;
    margin-bottom: 16px;
  }

  ul {
    margin-left: 32px;
    list-style-type: "- ";

    li {
      margin: 0;
    }
  }

  .pending-container {
    display: flex;

    .lysa-ui-spinner {
      max-width: 32px;
      display: inline-block;

      svg {
        transform: translate3d(-6px, 6px, 0) !important;
      }
    }
  }

  .signup-info {
    .info {
      margin-bottom: 16px;
    }

    p {
      margin: 0;
      font-size: 16px;
    }
  }
}
