@import "~@lysaab/ui-2/components/styling/layout";

body {
  margin: 8px;
  margin-bottom: 48px;
}

.demo-data {
  border-top: 1px solid black;
  padding: 4px 0;
  bottom: 0;
  width: calc(100% - 16px);
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .demo-data {
    flex-direction: row;
    position: fixed;
  }
}

.demo-data label {
  flex: 0 0 auto;
}

.demo-data input {
  display: inline-block;
  margin: 0 14px 0 4px;
}
